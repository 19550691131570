import { memo } from "react";

import "./Loader.css";

const Skeleton = () => (
  <aside className="full spinner">
    <div className="place-title load"></div>
    <div className="place-row load"></div>
    <div className="place-row load"></div>
    <div className="place-square mg-bottom load"></div>

    <div className="place-row load"></div>
    <div className="place-row load"></div>
    <div className="place-row load"></div>
  </aside>
);

export default memo(Skeleton);
