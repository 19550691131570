import {
  createContext,
  useReducer,
  useMemo,
  useContext,
  useEffect,
} from "react";

import { SET_PLAN, LOAD_PLANS } from "./actions";

import { Action, Flux, ProviderProps, OptionPlan } from "../types";
import { Plan } from "../models/plan";

import { getPlans } from "../services/plan";

export type PlanState = {
  plan?: OptionPlan;
  plans: OptionPlan[];
};

type ImmerDispatch = {
  draft: PlanState;
  produce: Flux;
};

const initialState: PlanState = {
  plan: undefined,
  plans: [],
};

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_PLAN:
      return { ...state, plan: action.payload };
    case LOAD_PLANS:
      return { ...state, plans: action.payload };
    default:
      throw new Error("Unexpected action type");
  }
};

export const ImmerContext = createContext<ImmerDispatch | undefined>(undefined);

export const PlanStore = ({ children }: ProviderProps) => {
  const [draft, produce] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialPlan = localStorage.getItem("plan");

    if (!initialPlan) {
      getPlans().then((result) => {
        const options = result.data.map((plan: Plan) => ({
          label: plan.name,
          value: plan.paypal_plan,
          cost: plan.cost,
          id: plan.id,
        }));

        produce({ type: LOAD_PLANS, payload: options });
        produce({ type: SET_PLAN, payload: options[0] });
      });
    } else {
      produce({ type: SET_PLAN, payload: JSON.parse(initialPlan || "null") });
    }
  }, []);

  const contextValue = useMemo(() => ({ draft, produce }), [draft]);

  return (
    <ImmerContext.Provider value={contextValue}>
      {children}
    </ImmerContext.Provider>
  );
};

export const usePlanStore = () => useContext(ImmerContext)!;
