import API from "./api";

export const registerUser = async (data = {}) => {
  const response = await API.post("auth/user", data);

  return response.data;
};

export const loginUser = async (data = {}) => {
  const response = await API.post("auth/access", data);

  return response.data;
};

export const logoutUser = async () => {
  const response = await API.delete("auth/access");

  return response.data;
};

export const reserPassword = async (data = {}) => {
  const response = await API.put("auth/password", data);

  return response.data;
};

export const forgotPassword = async (data = {}) => {
  const response = await API.post("auth/password", data);

  return response.data;
};

export const loginWithGoogle = async (data = {}) => {
  const response = await API.post("auth/access/google", data);

  return response.data;
};

export const updateCurrentPassword = async (data = {}) => {
  const response = await API.put("auth/user/password", data);

  return response.data;
};
