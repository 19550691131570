import API from "./api";

export const getProfile = async () => {
  const response = await API.get("auth/user");

  return response.data;
};

export const updateProfile = async (data = {}) => {
  const response = await API.put("auth/user", data);

  return response.data;
};
