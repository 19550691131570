export const CURRENCY = "£";
export const DATE_FORMAT = "YYYY-MM-DD";

export const PRIVACY_POLICY_DOC = "/privacy";
export const TERMS_USE_DOC = "/terms-of-use";
export const TERMS_SALE_DOC = "/terms-of-sales";

export const paypalOptions = {
  "client-id": process.env.REACT_APP_PAY_CLIENT_ID || "test",
  vault: true,
  intent: "subscription",
  currency: "GBP",
};

export const getBenefits = (price: number) => {
  if (price < 6) {
    return {
      label: "Most popular",
      time: "month",
    };
  } else if (price > 20 && price < 45) {
    return {
      // label: `Save ${CURRENCY}4.89`,
      label: `Save 30%`,
      time: "year",
    };
  } else {
    return {
      // label: `Save ${CURRENCY}0.98`,
      label: `Save 20%`,
      time: "3 month",
    };
  }
};
