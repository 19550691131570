import { memo } from "react";

const LinearProgress = () => (
  <aside className="material">
    <div className="place-line">
      <div className="progress-bar first"></div>
      <div className="progress-bar second"></div>
    </div>
  </aside>
);

export default memo(LinearProgress);
