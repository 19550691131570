import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import { UserStore } from "./store/context";
import { PlanStore } from "./store/plan";

import App from "./App";

import "notyf/notyf.min.css";
import "./index.css";

ReactDOM.render(
  <UserStore>
    <PlanStore>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PlanStore>
  </UserStore>,
  document.getElementById("root")
);
