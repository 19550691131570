import { FC } from "react";
import { Redirect, Route } from "react-router-dom";
import { RouteProps } from "react-router";

import Skeleton from "../components/Base/Loader/Skeleton";

import { useStore } from "../store/context";

const PrivateRoute: FC<RouteProps> = (props) => {
  const { store } = useStore();

  const isAuthenticated = store.isAuthenticated;
  const isLoading = store.isLoading;

  if (isLoading) {
    return <Skeleton />;
  }

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  } else {
    return <Route {...props} />;
  }
};

export default PrivateRoute;
