import { usePromiseTracker } from "react-promise-tracker";

import LinearProgress from "../../LinearProgress";

const Loader = () => {
  const { promiseInProgress } = usePromiseTracker();

  return promiseInProgress ? <LinearProgress /> : null;
};

export default Loader;
