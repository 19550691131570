import { FC } from "react";
import { Redirect, Route } from "react-router-dom";
import { RouteProps } from "react-router";

import { useStore } from "../store/context";

const PublicRoute: FC<RouteProps> = (props) => {
  const { store } = useStore();

  const isAuthenticated = store.isAuthenticated;

  if (!isAuthenticated) {
    return <Route {...props} />;
  } else {
    return <Redirect to="/" />;
  }
};

export default PublicRoute;
