import { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";

import { useStore } from "../../store/context";
import { RESET_PROFILE } from "../../store/actions";

import logo from "./logo.png";

import { logoutUser } from "../../services/auth";

import "./SideBar.css";

const SideBar = () => {
  const history = useHistory();

  const { store, dispatch } = useStore();

  const [menu, setMenu] = useState(false);

  const isAuthenticated = store.user ? store.user : localStorage.getItem("jwt");
  const isOnNextStep = store.isOnNextStep;

  useEffect(() => {
    history.listen((location, action) => setMenu(false));
  }, [history]);

  const handleSubmit = () => {
    trackPromise(
      logoutUser().finally(() => {
        dispatch({ type: RESET_PROFILE, payload: undefined });

        history.push("/login");
      })
    );
  };

  const handleBurger = () => setMenu(!menu);

  return (
    <nav className={menu ? "burger" : "nav flex space"}>
      <section className={menu ? "bar" : "flex bar"}>
        <a href="/">
          <img src={logo} alt="Logo" className="logo" width="220" height="68" />
        </a>

        {!isOnNextStep && (
          <NavLink
            exact
            to="/"
            className="flex title"
            activeClassName="selected"
          >
            <svg
              width="24"
              version="1.1"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g fill="white" fillRule="evenodd">
                <g transform="translate(-45 -440)">
                  <g transform="translate(0 58)">
                    <g transform="translate(40 377)">
                      <path d="m23.724 22.339c0.60129-0.73246 1.0973-1.5501 1.4881-2.453 0.52536-1.2138 0.78804-2.5091 0.78804-3.8859s-0.26268-2.6721-0.78804-3.8859c-0.52536-1.2138-1.2409-2.2735-2.1467-3.1793s-1.9656-1.6214-3.1793-2.1467c-1.2138-0.52536-2.5091-0.78804-3.8859-0.78804s-2.6721 0.26268-3.8859 0.78804c-1.2138 0.52536-2.2735 1.2409-3.1793 2.1467s-1.6214 1.9656-2.1467 3.1793c-0.52536 1.2138-0.78804 2.5091-0.78804 3.8859s0.26268 2.6721 0.78804 3.8859c0.39339 0.90887 0.89343 1.7314 1.5001 2.4676l3.7023-1.424c1.0709-0.41189 1.8196-1.3696 1.9782-2.483-1.1783-0.69597-1.9687-1.979-1.9687-3.4465v-2c0-2.2091 1.7909-4 4-4s4 1.7909 4 4v2c0 1.4483-0.76976 2.7169-1.9225 3.4189 0.15003 1.1252 0.90224 2.0953 1.982 2.5107l3.6643 1.4093zm-0.72531 0.79245-3.298-1.2684c-1.332-0.51232-2.2861-1.6648-2.566-3.0261-0.3597 0.1062-0.7405 0.1632-1.1346 0.1632-0.37693 0-0.74168-0.052135-1.0874-0.14959-0.28337 1.3553-1.2355 2.5018-2.5631 3.0124l-3.3339 1.2823c0.88785 0.86809 1.9207 1.557 3.0986 2.0669 1.2138 0.52536 2.5091 0.78804 3.8859 0.78804s2.6721-0.26268 3.8859-0.78804c1.1841-0.51252 2.2216-1.2061 3.1127-2.0807zm-6.9985 3.8687c-1.5145 0-2.9393-0.28895-4.2745-0.86685-1.3352-0.5779-2.5009-1.365-3.4973-2.3614s-1.7835-2.1621-2.3614-3.4973-0.86685-2.76-0.86685-4.2745 0.28895-2.9393 0.86685-4.2745 1.365-2.5009 2.3614-3.4973 2.1621-1.7835 3.4973-2.3614 2.76-0.86685 4.2745-0.86685 2.9393 0.28895 4.2745 0.86685 2.5009 1.365 3.4973 2.3614 1.7835 2.1621 2.3614 3.4973c0.5779 1.3352 0.86685 2.76 0.86685 4.2745s-0.28895 2.9393-0.86685 4.2745c-0.5779 1.3352-1.365 2.5009-2.3614 3.4973s-2.1621 1.7835-3.4973 2.3614c-1.3352 0.5779-2.76 0.86685-4.2745 0.86685zm0-17c-1.6569 0-3 1.3431-3 3v2c0 1.6569 1.3431 3 3 3s3-1.3431 3-3v-2c0-1.6569-1.3431-3-3-3z" />
                    </g>
                  </g>
                </g>
              </g>
            </svg>

            <p className="nav-item">Account</p>
          </NavLink>
        )}

        <NavLink
          exact
          to="/apps"
          className="flex title"
          activeClassName="selected"
        >
          <svg
            width="24"
            version="1.1"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="none" fillRule="evenodd">
              <g fillRule="nonzero">
                <rect width="32" height="32" />
              </g>
              <g fill="white">
                <path d="m9 0.45537 8.5 3.6705v5.5105c0 5.0433-3.5896 9.7125-8.3846 10.85l-0.11542 0.027383-0.11542-0.027383c-4.7949-1.1376-8.3846-5.8068-8.3846-10.85v-5.5105l8.5-3.6705zm0 1.0893-7.5 3.2386v4.8531c0 4.5447 3.2189 8.772 7.5 9.8489 4.2811-1.0769 7.5-5.3043 7.5-9.8489v-4.8531l-7.5-3.2386z" />
              </g>
            </g>
          </svg>

          <p className="nav-item">Apps</p>
        </NavLink>

        {isAuthenticated && (
          <span className="flex title" onClick={handleSubmit}>
            <svg
              width="22"
              enableBackground="new 0 0 471.2 471.2"
              version="1.1"
              viewBox="0 0 471.2 471.2"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="white"
                d="m227.62 444.2h-122.9c-33.4 0-60.5-27.2-60.5-60.5v-296.2c0-33.4 27.2-60.5 60.5-60.5h124.9c7.5 0 13.5-6 13.5-13.5s-6-13.5-13.5-13.5h-124.9c-48.3 0-87.5 39.3-87.5 87.5v296.2c0 48.3 39.3 87.5 87.5 87.5h122.9c7.5 0 13.5-6 13.5-13.5s-6.1-13.5-13.5-13.5z"
              />
              <path
                fill="white"
                d="m450.02 226.1-85.8-85.8c-5.3-5.3-13.8-5.3-19.1 0s-5.3 13.8 0 19.1l62.8 62.8h-273.9c-7.5 0-13.5 6-13.5 13.5s6 13.5 13.5 13.5h273.9l-62.8 62.8c-5.3 5.3-5.3 13.8 0 19.1 2.6 2.6 6.1 4 9.5 4s6.9-1.3 9.5-4l85.8-85.8c5.4-5.4 5.4-14 0.1-19.2z"
              />
            </svg>

            <p className="nav-item">Log out</p>
          </span>
        )}
      </section>

      <NavLink
        exact
        to="/support"
        className="flex title"
        activeClassName="selected"
      >
        <svg width="24" viewBox="0 0 24 24">
          <path
            fill="white"
            d="M23 14.325h-1.997c-1.436 0-1.99 1.409-.998 2.401l1.413 1.413a.5.5 0 0 1 0 .707l-2.577 2.576a.5.5 0 0 1-.707 0l-1.414-1.414c-1.003-1.002-2.401-.382-2.401.994V23a.5.5 0 0 1-.5.5h-3.645a.5.5 0 0 1-.5-.5v-2c0-1.23-1.544-1.851-2.397-.998L5.86 21.418a.5.5 0 0 1-.707 0L2.578 18.84a.5.5 0 0 1 0-.707l1.417-1.417c1.067-1.067.477-2.398-.993-2.398H1a.5.5 0 0 1-.5-.5v-3.645a.5.5 0 0 1 .5-.5h2.003c1.29 0 1.908-1.484.996-2.397L2.582 5.861a.5.5 0 0 1 0-.707L5.16 2.577a.5.5 0 0 1 .707 0l1.416 1.416c.824.821 2.4.152 2.4-.994V1a.5.5 0 0 1 .5-.5h3.643a.5.5 0 0 1 .5.5v2c0 1.228 1.512 1.884 2.4.995l1.414-1.413a.5.5 0 0 1 .707 0l2.576 2.577a.5.5 0 0 1 0 .707l-1.413 1.413c-.94.94-.343 2.402.995 2.402H23a.5.5 0 0 1 .5.5v3.644a.5.5 0 0 1-.5.5zm-.5-3.644h-1.496c-2.232 0-3.27-2.542-1.702-4.109l1.06-1.06-1.87-1.869-1.06 1.06c-1.526 1.526-4.107.405-4.107-1.703V1.5h-2.644V3c0 2.03-2.647 3.154-4.106 1.701L5.512 3.638l-1.869 1.87L4.706 6.57c1.543 1.544.476 4.104-1.703 4.104H1.5v2.645h1.502c2.345 0 3.408 2.396 1.7 4.105l-1.064 1.064 1.87 1.869 1.062-1.062c1.479-1.48 4.104-.422 4.104 1.705v1.5h2.645v-1.498c0-2.251 2.464-3.345 4.108-1.7l1.06 1.06 1.87-1.87-1.06-1.058c-1.614-1.615-.632-4.109 1.706-4.109H22.5v-2.644zM12 16.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm0-1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"
            stroke="none"
          ></path>
        </svg>

        <p className="nav-item">Support</p>
      </NavLink>

      <div className="menu" onClick={handleBurger}>
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
      </div>
    </nav>
  );
};

export default SideBar;
