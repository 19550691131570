import { Suspense, lazy } from "react";
import { Switch, Route, RouteComponentProps } from "react-router-dom";

import Skeleton from "./components/Base/Loader/Skeleton";
import Loader from "./components/Base/Loader";

import PrivateRoute from "./guards/PrivateRoute";
import PublicRoute from "./guards/PublicRoute";

import { ResetProps, EmailParams } from "./types";

import { PRIVACY_POLICY_DOC, TERMS_USE_DOC, TERMS_SALE_DOC } from "./utils/constants";

import SideBar from "./layouts/SideBar";
import Footer from "./layouts/Footer";

const NotFound = lazy(() => import("./pages/NotFound"));
const Login = lazy(() => import("./pages/Login"));
const SignUp = lazy(() => import("./pages/SignUp"));
const Home = lazy(() => import("./pages/Home"));
const Apps = lazy(() => import("./pages/Apps"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const StepPlan = lazy(() => import("./pages/StepPlan"));
const VerifyEmail = lazy(() => import("./pages/VerifyEmail"));
const GoogleAuth = lazy(() => import("./pages/GoogleAuth"));
const Support = lazy(() => import("./pages/Support"));
const DetachGmail = lazy(() => import("./pages/DetachGmail"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const TermsOfUse = lazy(() => import("./pages/TermsOfUse"));
const TermsOfSale = lazy(() => import("./pages/TermsOfSale"))

const App = () => (
  <div className="flex root">
    <SideBar />

    <main className="full content">
      <Suspense fallback={<Skeleton />}>
        <Switch>
          <PrivateRoute exact path="/" component={Home} />
          <PublicRoute path="/login" component={Login} />
          <PublicRoute path="/signup" component={SignUp} />
          <Route path="/apps" component={Apps} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/extension" component={StepPlan} />
          <Route
            path="/reset-password/:token"
            render={(props: RouteComponentProps<ResetProps>) => (
              <ResetPassword {...props} />
            )}
          />
          <Route
            path="/email/verify/:id/:expires/:signature"
            render={(props: RouteComponentProps<EmailParams>) => (
              <VerifyEmail {...props} />
            )}
          />
          <Route
            path="/auth/google"
            render={(props: RouteComponentProps<{}>) => (
              <GoogleAuth {...props} />
            )}
          />
          <Route path="/setup/google" component={DetachGmail} />
          <Route path="/support" component={Support} />
          <Route path={PRIVACY_POLICY_DOC} component={PrivacyPolicy} />
          <Route path={TERMS_USE_DOC} component={TermsOfUse} />
          <Route path={TERMS_SALE_DOC} component={TermsOfSale} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Suspense>

      <Footer />
    </main>

    <Loader />
  </div>
);

export default App;
