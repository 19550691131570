import { PRIVACY_POLICY_DOC } from "../../utils/constants";

import "./Footer.css";

const Footer = () => (
  <footer className="flex space all-rights px-13">
    <p>© {new Date().getFullYear()} Privci.io. All rights reserved.</p>
    <a href={PRIVACY_POLICY_DOC} className="link primary">
      Privacy Policy
    </a>
  </footer>
);

export default Footer;
